import React from 'react';
import styles from './ErrorAnimation.module.scss';

const ErrorAnimation = ({ message, colorMode }) => {
  return (
    <div
      className={`${styles.container} ${styles[colorMode]}`}
      data-testid="climoErrorContainer"
    >
      <div className={styles.glitchWrapper}>
        <div className={styles.glitch} data-text={message}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ErrorAnimation;
