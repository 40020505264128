const moment = require('moment');

// Timezones do not need to specifically be managed throughout the user flow in the widget
// because the timezones are all environment specific, and passed between environments
// as a string in a query param formatted as either MM-DD-YYYY or YYYY-MM-DD.
// Across the widget frontend, all dates are in user's local timezone.
// In proxy server, dates are in server timezone.
// In core, dates are in timezone of location sent with the request.

const defaultDate = () => {
  const defaultDaysOut = 90;
  return moment().add(defaultDaysOut, 'days');
};

const tenDaysFromToday = () => {
  return moment().add(10, 'days');
};

const jsDate = (date) => {
  return new Date(date);
};

const momentObject = (date) => {
  return moment(date, 'MM-DD-YYYY');
};

const year = (date) => {
  return momentObject(date).year();
};

const getWeekOfObject = (date) => {
  const prevSunday = momentObject(date).day('Sunday');

  if (year(prevSunday) === year(date)) {
    return prevSunday;
  }

  return prevSunday.add(7, 'days');
};

const isValid = (date) => {
  return momentObject(date).isValid();
};

const formattedMomentDate = (date) => {
  return momentObject(date).format('MM-DD-YYYY');
};

// This function is needed for the date picker and chart in Safari.
// In Safari, new Date('12-31-2020') is invalid, so needs to be passed to moment first and then to new Date().
const dateToJS = (date) => {
  const dateMoment = momentObject(date);
  const dateJS = jsDate(date);
  return dateJS.valueOf() ? dateJS : jsDate(dateMoment);
};

const getAnnotatedTime = (date) => {
  const annotatedDate = dateToJS(date);
  return annotatedDate.getTime();
};

export default {
  defaultDate,
  tenDaysFromToday,
  momentObject,
  getWeekOfObject,
  isValid,
  year,
  formattedMomentDate,
  jsDate,
  dateToJS,
  getAnnotatedTime
};
