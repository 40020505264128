import React from 'react';
import styles from './ActivityFooter.module.scss';

const ActivityFooter = ({
  insuranceClickThrough,
  stylesheet,
  colorMode,
  buildGAEvent
}) => {
  const stylesheetWrapper = stylesheet;

  return (
    <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
      <div className={styles.container}>
        {insuranceClickThrough ? (
          <div className={styles.action} data-testid="clickthrough">
            Weather Insurance can reimburse you if the weather is less than
            ideal for your trip.{' '}
            <a
              onClick={() =>
                buildGAEvent({
                  eventType: 'generateLead',
                  params: {
                    value: 1,
                    type: 'insurance_cta'
                  }
                })
              }
              className={styles.link}
              href="https://www.sensibletrips.com/what-is-weather-insurance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more &gt;
            </a>
          </div>
        ) : null}
        <div className={styles.powered} onClick={() => {
          buildGAEvent({
            eventType: 'click',
            params: {
              type: 'Powered By Sensible'
            }
          })
        }}>Powered by Sensible</div>
      </div>
    </div>
  );
};

export default ActivityFooter;
