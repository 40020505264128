import React, { useState } from 'react';
import styles from './ScrollBar.module.scss';

const ScrollBar = ({ scrollPercent, colorMode, handleScrollBarDrag }) => {
  const [isDragging, setIsDragging] = useState(false);

  const thumbHeight = 50;
  const borderRadiusBuffer = 5;

  const adjustedOffsetHeight = (offsetHeight) => {
    return offsetHeight - thumbHeight - borderRadiusBuffer;
  };

  const top = () => {
    const element = document.getElementById('scrollContainer');
    if (element) {
      return adjustedOffsetHeight(element.offsetHeight) * scrollPercent;
    }
  };

  const startDragging = (event) => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const stopDragging = (event) => {
    event.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const mouseMove = (event) => {
    if (!isDragging) return;

    const element = document.getElementById('scrollContainer');

    if (element) {
      const topBound = element.getBoundingClientRect().top;
      const movement = event.clientY - topBound - thumbHeight / 2;

      if (movement === 0) return;

      handleScrollBarDrag(
        movement / adjustedOffsetHeight(element.offsetHeight)
      );
    }
  };

  return (
    <div
      className={`${styles.container} ${styles[colorMode]}`}
      id="scrollContainer"
      draggable="true"
      onMouseDown={startDragging}
      onMouseMove={mouseMove}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
    >
      <div className={styles.thumb} style={{ top: top() }} />
    </div>
  );
};

export default ScrollBar;
