import React, { PureComponent } from 'react';
import Chart from '../Chart/Chart';

/* eslint-disable no-underscore-dangle */
class RainChart extends PureComponent {
  state = {
    dataIndex: 0
  };

  setDataIndexState = (i) => {
    if (!this._isMounted) return;

    this.setState({
      dataIndex: i
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { data, chartProps, chartName, weekOfObj } = this.props;

    const numTicks = 10;
    const chartMax = 50;

    const { rainBlue, labelColor } = chartProps.inlineStyles;

    const config = {
      mainDataSet: data,
      chart: {
        id: chartName
      },
      markers: {
        strokeColors: [rainBlue],
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: this.state.dataIndex,
            fillColor: rainBlue,
            strokeColor: rainBlue,
            size: 5
          }
        ]
      },
      tooltip: {
        y: {
          formatter: (value) => {
            let chance = value;

            if (chance < 1) chance = 'less than 1';
            if (chance >= chartMax) chance = 'more than 50';

            return `There is a ${chance}% chance the day will be impacted by rain.`;
          },
          title: {
            formatter: () => {
              '';
            }
          }
        }
      },
      dataLabels: {
        offsetX: 0,
        offsetY: -8
      },
      colors: [rainBlue],
      legend: {
        formatter: () => {
          return 'Sensible Rain Score';
        }
      },
      yaxis: {
        tickAmount: numTicks,
        min: 0,
        max: chartMax,
        labels: {
          minWidth: 45,
          offsetX: 5,
          formatter: (value) => {
            const roundedVal = value.toFixed(2);
            if (roundedVal === (chartMax / numTicks).toFixed(2)) {
              return 'Rarely';
            }

            if (roundedVal === (chartMax - chartMax / numTicks).toFixed(2)) {
              return 'Often';
            }
          },
          style: {
            colors: labelColor
          }
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      series: [
        {
          type: 'line',
          name: 'Sensible Rain Score',
          data
        }
      ]
    };

    return (
      <Chart
        weekOfObj={weekOfObj}
        chartProps={chartProps}
        chartConfig={config}
        setDataIndexState={this.setDataIndexState}
        unit="%"
        dataIndex={this.state.dataIndex}
      />
    );
  }
}

export default RainChart;
