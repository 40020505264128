import React, { useState, useEffect } from 'react';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import ActivityAPI from './api/activity';
import config from './config/config';
import './styles/global.scss';

function App() {
  const [fetchedConfig, setFetchedConfig] = useState(false);

  const missingConfigVar = () => {
    return config.some((c) => !window[c]);
  };

  const createGTagManagerScript = () => {
    const script = document.createElement('script');
    script.setAttribute('async', true);
    script.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${window.REACT_APP_GA_TRACKING_ID}`
    );

    return script;
  };

  const createGTagScript = () => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    script.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${window.REACT_APP_GA_TRACKING_ID}');
    `;

    return script;
  };

  const createAndAppendGAScripts = () => {
    const gTagScript = createGTagScript();
    const gTagManagerScript = createGTagManagerScript();

    document.head.insertBefore(gTagScript, document.head.childNodes[0]);
    document.head.insertBefore(gTagManagerScript, document.head.childNodes[0]);
  };

  useEffect(() => {
    if (fetchedConfig) return;

    const getAndSetConfigVars = async () => {
      try {
        const res = await ActivityAPI.getConfig();

        if (!res.data) return <div>Error fetching config vars</div>;

        config.forEach((c) => {
          if (!res.data[c]) return 'Missing config var';
          window[c] = res.data[c];
        });

        createAndAppendGAScripts();
        setFetchedConfig(true);
      } catch (err) {
        setFetchedConfig(true);
        return err;
      }
    };
    getAndSetConfigVars();
  });

  if (!fetchedConfig) {
    return <div />;
  }

  if (missingConfigVar()) {
    return <div>Error: Missing config var</div>;
  }

  return <DefaultLayout />;
}

export default App;
