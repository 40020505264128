const sharedClassic = {
  dashArray: 5,
  strokeWidth: 2,
  showGrid: true,
  axisBorder: {
    show: true
  },
  axisTicks: {
    show: true
  }
};

const sharedMinimalist = {
  dashArray: 0,
  strokeWidth: 1.5,
  showGrid: false,
  axisBorder: {
    show: true,
    color: '#555'
  },
  axisTicks: {
    show: true,
    color: '#555'
  }
};

export default {
  classic: {
    light: {
      ...sharedClassic,
      theme: 'light',
      red: '#E74C38',
      rainBlue: '#6790BB',
      snowBlue: '#35D7E4',
      labelColor: '#555',
      dataLabels: {
        backgroundColors: ['#FFF'],
        foreColor: '#313131',
        borderColor: []
      },
      annotations: {
        borderColor: '#C4C4C4',
        color: '#555',
        background: '#313131'
      }
    },
    dark: {
      ...sharedClassic,
      theme: 'dark',
      red: '#CB0025',
      rainBlue: '#6069F1',
      snowBlue: '#BCF6F6',
      labelColor: 'rgba(255,255,255,0.87)',
      dataLabels: {
        backgroundColors: ['#292A2D'],
        foreColor: 'rgba(255,255,255,0.87)',
        borderColor: '#292A2D'
      },
      annotations: {
        borderColor: 'rgba(255,255,255,0.87)',
        color: 'rgba(255,255,255,0.87)',
        background: '#292A2D'
      }
    }
  },
  minimalist: {
    light: {
      ...sharedMinimalist,
      theme: 'light',
      red: '#CA5B54',
      rainBlue: '#81AECE',
      snowBlue: '#9BD6D3',
      labelColor: '#313131',
      dataLabels: {
        backgroundColors: ['#FFF'],
        foreColor: '#313131'
      },
      annotations: {
        borderColor: '#C4C4C4',
        color: '#555',
        background: '#313131'
      }
    },
    dark: {
      ...sharedMinimalist,
      theme: 'dark',
      red: '#CA5B54',
      rainBlue: '#81AECE',
      snowBlue: '#A8D5D5',
      labelColor: 'rgba(255,255,255,0.87)',
      dataLabels: {
        backgroundColors: ['#292A2D'],
        foreColor: 'rgba(255,255,255,0.87)'
      },
      annotations: {
        borderColor: 'rgba(255,255,255,0.87)',
        color: 'rgba(255,255,255,0.87)',
        background: '#292A2D'
      }
    }
  }
};
