import React, { Component } from 'react';
import Script from 'react-load-script';
import styles from './GoogleMapsInput.module.scss';
import PoweredByGoogle from '../../assets/powered_by_google_on_white.png';
import PoweredByGoogleDark from '../../assets/powered_by_google_on_non_white.png';
/* global google */

class GoogleMapsInput extends Component {
  state = {
    predictions: [],
    prevIndex: 0,
    index: 0,
    overlay: 0
  };

  initService = () => {
    this.service = new google.maps.places.AutocompleteService();
  };

  getLocationTextContext = (el) => {
    if (!el) {
      return '';
    }

    if (!el.childNodes || !el.childNodes.item(1)) {
      return el.innerText;
    }

    return el.childNodes.item(1).textContent;
  };

  predictionMouseDown = async (event) => {
    event.preventDefault();
    const { handleGoogleNavigation, handleOnSubmit, buildGAEvent } = this.props;
    const location = this.getLocationTextContext(event.currentTarget);
    if (location.length === 0) return;

    buildGAEvent({
      eventType: 'search',
      params: {
        searchTerm: location,
        searchType: 'location_input'
      }
    });

    handleGoogleNavigation(location);
    handleOnSubmit(event);
    this.removeGoogleSuggestions();
    document.getElementById('inputLocation').blur();
  };

  onBlur = () => {
    this.setState({
      overlay: 1
    });

    this.removeGoogleSuggestions();
  };

  removeGoogleSuggestions = () => {
    document.getElementById('results').innerHTML = '';
  };

  // Renders updated suggestions when typing
  renderGoogleSuggestions = (event) => {
    event.preventDefault();
    const input = event.currentTarget.value;

    // Removes suggestions if no location input entered
    if (input.length === 0) {
      this.removeGoogleSuggestions();
      return this.setState({
        predictions: [],
        prevIndex: 0,
        index: 0
      });
    }

    const predictionsArray = [];
    // Create prediction objects and append powered by Google
    const displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) return;
      this.setState({
        index: 0,
        prevIndex: 0
      });

      this.removeGoogleSuggestions();

      // Create powered by Google image to append
      const poweredDiv = document.createElement('div');
      poweredDiv.className = styles.poweredDiv;
      const poweredByGoogle = document.createElement('img');
      const poweredByImg =
        this.props.colorMode === 'light'
          ? PoweredByGoogle
          : PoweredByGoogleDark;

      poweredByGoogle.src = poweredByImg;
      poweredDiv.appendChild(poweredByGoogle);
      predictions.forEach((prediction, i) => {
        const div = document.createElement('div');

        // Class is used in onChangeHandler for conditions
        if (i === this.state.highlightedId) {
          div.setAttribute('class', `${styles.highlighted} suggestedLocation`);
        }

        div.setAttribute('class', `${styles.prediction} suggestedLocation`);
        div.setAttribute('id', prediction.place_id);
        div.addEventListener('mousedown', this.predictionMouseDown);

        const mainTextWrapper = document.createElement('span');
        mainTextWrapper.setAttribute('class', styles.mainText);
        mainTextWrapper.appendChild(
          document.createTextNode(prediction.structured_formatting.main_text)
        );
        div.appendChild(mainTextWrapper);

        const fullAddressTextWrapper = document.createElement('span');
        fullAddressTextWrapper.appendChild(
          document.createTextNode(prediction.description)
        );

        div.appendChild(fullAddressTextWrapper);
        document.getElementById('results').appendChild(div);

        predictionsArray.push({
          id: prediction.place_id,
          value: prediction.description
        });
      });
      document.getElementById('results').appendChild(poweredDiv);

      this.setState({
        predictions: predictionsArray
      });

      const el = document.getElementById(predictionsArray[this.state.index].id);
      el.setAttribute('class', `${styles.highlighted} suggestedLocation`);
    };

    return this.service.getPlacePredictions({ input }, displaySuggestions);
  };

  changeKeyState = (keyCode) => {
    const arrowDown = 40;
    const arrowUp = 38;
    const { predictions, index } = this.state;

    let newIndex = index;

    if (keyCode === arrowDown) {
      newIndex += 1;
    }

    if (keyCode === arrowUp) {
      newIndex -= 1;
    }

    if (newIndex < 0) {
      newIndex = predictions.length - 1;
    }

    if (newIndex > predictions.length - 1) {
      newIndex = 0;
    }

    this.setState({
      prevIndex: index,
      index: newIndex
    });
  };

  handleKeyDown = async (event) => {
    const { keyCode } = event;
    if (keyCode !== 40 && keyCode !== 38) return;

    await this.changeKeyState(keyCode);

    const { predictions, prevIndex, index } = this.state;

    const prevEl = document.getElementById(predictions[prevIndex].id);
    const el = document.getElementById(predictions[index].id);

    if (!prevEl || !el) return;

    prevEl.setAttribute('class', `${styles.prediction} suggestedLocation`);
    el.setAttribute('class', `${styles.highlighted} suggestedLocation`);

    const { handleGoogleNavigation } = this.props;
    const location = this.getLocationTextContext(
      document.getElementById(predictions[index].id)
    );
    handleGoogleNavigation(location);
  };

  // Updates widget container state and Google suggestions
  handleGoogleChange = (event) => {
    event.preventDefault();
    const { handleOnChange } = this.props;
    handleOnChange(event);
    this.renderGoogleSuggestions(event);
  };

  handleOnClickOverlay = (event) => {
    event.preventDefault();
    document.getElementById('inputLocation').focus();

    this.setState({
      overlay: 0
    });
  };

  componentDidUpdate(prevProps) {
    const prevLoading = prevProps.loading;
    const { loading } = this.props;

    if (!prevLoading && loading) {
      return this.setState({
        overlay: 0
      });
    }

    if (prevLoading && !loading) {
      return this.setState({
        overlay: 1
      });
    }
  }

  render() {
    const {
      forwardedRef,
      icon,
      location,
      stylesheet,
      colorMode,
      loading
    } = this.props;
    const { overlay } = this.state;

    return (
      <div
        className={`${styles.container} ${styles[stylesheet]} ${styles[colorMode]}`}
      >
        {/* <div className={styles.wrapper}> */}
          {icon}
          <Script
            url={`${window.REACT_APP_GOOGLE_PLACES_URL}?key=${window.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`}
            onLoad={this.initService}
          />
          <div className={styles.overlayWrapper}>
            <input
              id="inputLocation"
              className={`${styles.input} GoogleMapsInput`}
              value={location}
              onChange={this.handleGoogleChange}
              onFocus={this.renderGoogleSuggestions}
              onBlur={this.onBlur}
              onKeyDown={this.handleKeyDown}
              ref={forwardedRef}
              disabled={!!loading}
            />
            <div
              onClick={this.handleOnClickOverlay}
              className={overlay ? styles.overlay : styles.hideOverlay}
              data-testid="locationInputOverlay"
            />
          </div>
          <div id="results" data-testid="results" className={styles.results} />
        {/* </div> */}
      </div>
    );
  }
}

export default GoogleMapsInput;
