import React from 'react';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styles from './DatePickerInput.module.scss';
import acceptedDateFormats from './AcceptedDateFormats';
import DateFormats from '../../formats/date';
import 'react-day-picker/lib/style.css';

const DatePickerInput = React.forwardRef((props, ref) => {
  const {
    handleOnChange,
    handleOnDateSelect,
    icon,
    dateInput,
    stylesheet,
    colorMode,
    loading,
    buildGAEvent
  } = props;
  const onChange = (selectedDay, modifiers, dayPickerInput) => {
    const { value } = dayPickerInput.input;
    return handleOnChange(value);
  };

  const jsDate = DateFormats.dateToJS(dateInput);

  const formatAndSelectDate = (date) => {
    const formattedDate = DateFormats.formattedMomentDate(date);

    buildGAEvent({
      eventType: 'search',
      params: {
        searchTerm: formattedDate,
        searchType: 'date_input'
      }
    });

    return handleOnDateSelect(formattedDate);
  };

  const formatAndUpdateInput = () => {
    const formattedSelected = DateFormats.formattedMomentDate(
      ref.current.state.selectedDays
    );
    return handleOnChange(formattedSelected);
  };

  return (
    // ${stylesheet} ${colorMode} allows styling package classes from global stylesheet
    <div
      className={`${stylesheet} ${colorMode} ${styles[stylesheet]} ${styles[colorMode]}`}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {icon}
          <DayPickerInput
            // gets called when typing and on select
            onDayChange={onChange}
            // only gets called on select
            formatDate={formatDate}
            // only gets called on typing
            parseDate={parseDate}
            format={[...acceptedDateFormats]}
            value={dateInput}
            inputProps={{ disabled: !!loading, onBlur: formatAndUpdateInput }}
            placeholder={DateFormats.formattedMomentDate(
              DateFormats.defaultDate()
            )}
            disabled={!!loading}
            // Remove comment for development on DayPickerInput
            // keepFocus={false}
            // showOverlay={true}

            ref={ref}
            dayPickerProps={{
              onDayClick: formatAndSelectDate,
              selectedDays: jsDate
            }}
            data-testid="dateInput"
          />
        </div>
      </div>
    </div>
  );
});

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
