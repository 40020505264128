import React, { PureComponent } from 'react';
import styles from './Inputs.module.scss';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import GoogleMapsInput from '../../components/GoogleMapsInput/GoogleMapsInput';
import Error from '../../components/Error/Error';
import Warning from '../../components/Warning/Warning';
import DateFormats from '../../formats/date';
import { ReactComponent as Pin } from '../../assets/icons/pin.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';

class Inputs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputLocationOverlay: true,
      dateLocationOverlay: true
    };

    this.dayPicker = React.createRef();
    this.googleInput = React.createRef();
  }

  render() {
    const {
      handleOnChange,
      handleOnSubmit,
      handleOnDateSelect,
      handleOnDateChange,
      handleGoogleNavigation,
      location,
      dateInput,
      error,
      stylesheet,
      colorMode,
      loading,
      buildGAEvent
    } = this.props;

    const stylesheetWrapper = stylesheet;

    // if prev & active = 0, grab first prediction and send location
    const hideAndSubmit = async (event) => {
      event.preventDefault();
      const { predictions, index, prevIndex } = this.googleInput.current.state;

      if (index === 0 && prevIndex === 0 && predictions.length > 0) {
        const firstPrediction = predictions[0].value;
        await handleGoogleNavigation(firstPrediction);
      }

      const momentDate = DateFormats.momentObject(
        this.dayPicker.current.state.selectedDays
      );
      const formattedDate = DateFormats.formattedMomentDate(momentDate);

      await handleOnDateChange(formattedDate);
      handleOnSubmit(event);
      const googleInput = document.getElementById('inputLocation');
      this.dayPicker.current.hideAfterDayClick();
      this.googleInput.current.removeGoogleSuggestions();
      if (googleInput) {
        googleInput.blur();
      }
    };

    const shouldRenderWarning = (date) => {
      const tenDaysFromToday = DateFormats.tenDaysFromToday();
      const momentObj = DateFormats.momentObject(date);
      return (
        DateFormats.isValid(momentObj) &&
        momentObj.isSameOrBefore(tenDaysFromToday)
      );
    };

    const renderWarning = () => {
      if (shouldRenderWarning(dateInput)) {
        return (
          <Warning
            firstLine="This view is meant for dates more than 10 days in the future."
            secondLine="Near future and historic data does not reflect real observations."
          />
        );
      }

      return null;
    };

    const status = loading ? styles.disabled : '';

    return (
      <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
        <div className={styles.container}>
          <div className={styles.header}>Check the Weather for Your Trip</div>
          <form
            className={styles.wrapper}
            onSubmit={loading ? null : hideAndSubmit}
          >
            <div className={`${styles.overlayWrapper} ${status}`}>
              <GoogleMapsInput
                handleOnChange={handleOnChange}
                handleOnSubmit={handleOnSubmit}
                handleGoogleNavigation={handleGoogleNavigation}
                location={location}
                ref={this.googleInput}
                icon={<Pin className={styles.icon} />}
                stylesheet={stylesheet}
                colorMode={colorMode}
                loading={loading}
                buildGAEvent={buildGAEvent}
              />
            </div>
            <div className={`${styles.overlayWrapper} ${status}`}>
              <DatePickerInput
                dateInput={dateInput}
                handleOnChange={handleOnDateChange}
                handleOnDateSelect={handleOnDateSelect}
                ref={this.dayPicker}
                icon={<Calendar className={styles.icon} />}
                stylesheet={stylesheet}
                colorMode={colorMode}
                loading={loading}
                buildGAEvent={buildGAEvent}
              />
            </div>
            <input type="submit" className={styles.submit} />
            {renderWarning()}
          </form>
          <Error error={error} stylesheet={stylesheet} />
        </div>
      </div>
    );
  }
}

export default Inputs;
