import React from 'react';
import styles from './Error.module.scss';

const Error = ({ error, stylesheet }) => {
  return (
    <div className={`${styles.container} ${styles[stylesheet]}`}>{error}</div>
  );
};

export default Error;
