import React, { Component } from 'react';
import Chart from '../Chart/Chart';

/* eslint-disable no-underscore-dangle */
class TemperatureChart extends Component {
  state = {
    dataIndex: 0
  };

  setDataIndexState = (i) => {
    if (!this._isMounted) return;

    this.setState({
      dataIndex: i
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { data, chartProps, chartName, weekOfObj } = this.props;
    const { highs, lows, minTemp, maxTemp } = data;

    // Temp chart min and max X-axis values are set dynamically, to dataset min and max values -/+ 5
    // Rounded down/up to the nearest multiple of 10
    const adjustedMinTemp = () => {
      if ((minTemp - 5) % 10 === 0) {
        return minTemp - 5;
      }

      return Math.floor((minTemp - 5) / 10) * 10;
    };

    const adjustedMaxTemp = () => {
      if ((maxTemp + 5) % 10 === 0) {
        return maxTemp + 5;
      }

      return Math.ceil((maxTemp + 5) / 10) * 10;
    };

    const { red, rainBlue, labelColor } = chartProps.inlineStyles;

    const config = {
      mainDataSet: highs,
      chart: {
        id: chartName
      },
      markers: {
        strokeColors: [red, rainBlue],
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: this.state.dataIndex,
            fillColor: red,
            strokeColor: red,
            size: 5
          },
          {
            seriesIndex: 1,
            dataPointIndex: this.state.dataIndex,
            fillColor: rainBlue,
            strokeColor: rainBlue,
            size: 5
          }
        ]
      },
      dataLabels: {
        offsetX: 6,
        offsetY: 6
      },
      colors: [red, rainBlue],
      legend: {},
      yaxis: {
        tickAmount: 2,
        min: adjustedMinTemp(),
        max: adjustedMaxTemp(),
        labels: {
          minWidth: 45,
          formatter(value) {
            return `${value} °F`;
          },
          style: {
            colors: labelColor
          }
        }
      },
      tooltip: {
        y: {}
      },
      grid: {
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      series: [
        {
          name: 'High',
          data: highs
        },
        {
          name: 'Low',
          data: lows
        }
      ]
    };

    return (
      <Chart
        weekOfObj={weekOfObj}
        chartProps={chartProps}
        chartConfig={config}
        setDataIndexState={this.setDataIndexState}
        unit="°"
        dataIndex={this.state.dataIndex}
      />
    );
  }
}

export default TemperatureChart;
