/* global gtag */

const createTracker = ({ clientId }) => {
  gtag('create', window.REACT_APP_GA_TRACKING_ID, {
    storage: 'none',
    clientId
  });
};

const search = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.searchTerm) gTagParams.search_term = params.searchTerm;
  if (params.searchType) gTagParams.search_type = params.searchType;
  if (params.searchDate) gTagParams.search_date = params.searchDate;
  if ('requestSent' in params) gTagParams.request_sent = params.requestSent;

  gtag('event', 'search', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const click = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.chart) gTagParams.chart = params.chart;
  if (params.selectedDate) gTagParams.selected_date = params.selectedDate;
  if (params.type) gTagParams.type = params.type;

  gtag('event', 'click', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const viewItemList = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.itemListName) gTagParams.item_list_name = params.itemListName;
  if (params.action) gTagParams.action = params.action;

  gtag('event', 'view_item_list', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const selectItem = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.itemListName) gTagParams.item_list_name = params.itemListName;
  if (params.items) gTagParams.items = params.items;

  gtag('event', 'select_item', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const userEngagement = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.timeSec) gTagParams.engagement_time_msec = params.timeSec * 1000;
  if (params.hover) gTagParams.hover = params.hover;

  gtag('event', 'user_engagement', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const generateLead = ({ params, renderedLocation, renderedDate }) => {
  const gTagParams = {};

  if (params.value) gTagParams.value = params.value;
  if (params.type) gTagParams.type = params.type;

  gtag('event', 'generate_lead', {
    ...gTagParams,
    rendered_location: renderedLocation,
    rendered_date: renderedDate
  });
};

const sessionStart = ({ params }) => {
  gtag('event', 'session_start', {
    ...params
  });
};

export default {
  createTracker,
  search,
  click,
  viewItemList,
  selectItem,
  userEngagement,
  generateLead,
  sessionStart
};
