import React from 'react';
import styles from './Toggle.module.scss';
import { ReactComponent as Umbrella } from '../../assets/icons/umbrella.svg';
import { ReactComponent as Thermometer } from '../../assets/icons/thermometer.svg';
import { ReactComponent as Snow } from '../../assets/icons/snow.svg';

const Toggle = ({
  activeChart,
  handleOnToggle,
  stylesheet,
  colorMode,
  renderedCharts
}) => {
  const toggleMapper = {
    tempChart: {
      id: 'tempChart',
      title: 'Temperature',
      activeClass: styles.temp,
      icon: <Thermometer className={styles.icon} />
    },
    rainChart: {
      id: 'rainChart',
      title: 'Rain',
      activeClass: styles.rain,
      icon: <Umbrella className={styles.icon} />
    },
    snowChart: {
      id: 'snowChart',
      title: 'Snow',
      activeClass: styles.snow,
      icon: <Snow className={styles.icon} />
    }
  };

  const renderToggles = () => {
    return renderedCharts.map((t) => {
      const values = toggleMapper[t];

      const toggleClasses =
        activeChart === values.id
          ? `${styles.active} ${values.activeClass}`
          : styles.inactive;

      return (
        <div
          className={toggleClasses}
          toggleto={values.id}
          key={values.id}
          onClick={handleOnToggle}
        >
          <div className={styles.iconWrapper}>{values.icon}</div>
          {values.title}
        </div>
      );
    });
  };

  return (
    <div className={`${styles[stylesheet]} ${styles[colorMode]}`}>
      <div className={styles.container}>{renderToggles()}</div>
    </div>
  );
};

export default Toggle;
