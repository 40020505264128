import React, { useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './ScoreScale.module.scss';

const ScoreScale = ({
  name,
  score,
  icon,
  tooltipText,
  stylesheet,
  colorMode,
  buildGAEvent,
  empty
}) => {
  const [hover, setHover] = useState(false);

  const hoverRef = useRef();
  const timerRef = useRef();
  hoverRef.current = hover;

  const value = score;
  const stylesheetWrapper = stylesheet;

  const triggerGAEvent = (seconds) => {
    buildGAEvent({
      eventType: 'userEngagement',
      params: {
        timeSec: seconds,
        hover: name
      }
    });
  };

  const hoverTimer = () => {
    let seconds = 0.5;
    timerRef.current = setInterval(() => {
      if (hoverRef.current === true) {
        seconds += 0.5;
      } else {
        triggerGAEvent(seconds);
        clearInterval(timerRef.current);
      }
    }, 500);
  };

  // hover over, block state change until mouse leaves
  const handleMouseOver = () => {
    if (hover) return;
    setHover(true);

    // delay .5 seconds before starting timer
    setTimeout(() => {
      // if mouse has not left, start timer
      if (hoverRef.current) {
        hoverTimer();
      }
    }, 500);
  };

  // on mouse leave, end timer
  const handleMouseLeave = () => {
    if (!hover) return;
    setHover(false);
  };

  return (
    <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
      <div data-testid="scoreScaleContainer" className={styles.container}>
        <div className={styles.dotsWrapper}>
          <div className={styles.dotWrapper}>
            <div
              className={
                value >= 0.8 ? `${styles.active} ${styles.five}` : styles.score
              }
            />
          </div>
          <div className={styles.dotWrapper}>
            <div
              className={
                value >= 0.6 && value < 0.8
                  ? `${styles.active} ${styles.four}`
                  : styles.score
              }
            />
          </div>
          <div className={styles.dotWrapper}>
            <div
              className={
                value >= 0.4 && value < 0.6
                  ? `${styles.active} ${styles.three}`
                  : styles.score
              }
            />
          </div>
          <div className={styles.dotWrapper}>
            <div
              className={
                value >= 0.2 && value < 0.4
                  ? `${styles.active} ${styles.two}`
                  : styles.score
              }
            />
          </div>
          <div className={styles.dotWrapper}>
            <div
              className={
                value < 0.2 ? `${styles.active} ${styles.one}` : styles.score
              }
            />
          </div>
        </div>
        <div
          className={`${styles.icon} ${colorMode}`}
          data-tip={tooltipText || name}
          onMouseOver={empty ? null : handleMouseOver}
          onMouseLeave={empty ? null : handleMouseLeave}
        >
          {icon}
          <ReactTooltip className={styles.toolTip} />
        </div>
        <div className={styles.wrapper}>{name}</div>
      </div>
    </div>
  );
};

export default ScoreScale;
