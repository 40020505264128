import React from 'react';
import styles from './ActivityHeader.module.scss';
import ActivitySelect from '../ActivitySelect/ActivitySelect';

const ActivityHeader = ({
  weekOfObj,
  activity,
  activityScores,
  handleOnChange,
  stylesheet,
  colorMode,
  buildGAEvent
}) => {
  const stylesheetWrapper = stylesheet;

  if (!activity || !activityScores || !weekOfObj) {
    return (
      <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
        <div className={styles.container}>
          <div
            className={`${styles.scoreWrapper} ${styles.scoreWrapperPlaceholder}`}
          >
            What will it be a great day for?
          </div>
        </div>
      </div>
    );
  }

  const { overallScore } = activityScores[activity];
  const scoreClass = overallScore
    ? `${overallScore[0].toLowerCase()}${overallScore.slice(1)}`
    : null;

  return (
    <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
      <div className={styles.container}>
        <div className={styles.date} data-testid="activityScoreForWeekOf">
          Activity Score for week of {weekOfObj.format('MMMM D')}
        </div>
        <div className={styles.scoreWrapper}>
          {overallScore ? (
            <>
              <div
                className={`${styles.score} ${styles[scoreClass]}`}
                data-testid={scoreClass}
              >
                {overallScore}
              </div>
              <div className={styles.weatherFor}>weather for</div>
            </>
          ) : (
            <div>Score not available for &nbsp;</div>
          )}
          <ActivitySelect
            activity={activity}
            activityScores={activityScores}
            handleOnChange={handleOnChange}
            stylesheet={stylesheet}
            colorMode={colorMode}
            buildGAEvent={buildGAEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityHeader;
