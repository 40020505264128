const acceptedDateFormats = [
  'MM-DD-YYYY',
  'M-D-YYYY',
  'MM-DD-YY',
  'M-D-YY',
  'MM/DD/YYYY',
  'M/D/YYYY',
  'MM/DD/YY',
  'M/D/YY',
  'MMMM DD YYYY',
  'MMMM D YYYY',
  'MMM DD YYYY',
  'MMM D YYYY',
  'MMMM DD YY',
  'MMMM D YY',
  'MMM DD YY',
  'MMM D YY',
  'MMMM DD, YYYY',
  'MMMM D, YYYY',
  'MMM DD, YYYY',
  'MMM D, YYYY',
  'MMMM DD, YY',
  'MMMM D, YY',
  'MMM DD, YY',
  'MMM D, YY'
];

export default acceptedDateFormats;
