import React from 'react';
import styles from './Warning.module.scss';

const Warning = ({ firstLine, secondLine }) => {
  return (
    <div className={styles.container} data-testid="warningContainer">
      <div>{firstLine}</div>
      <div>{secondLine}</div>
    </div>
  );
};

export default Warning;
