import { Widget } from '../pages';
import healthCheck from '../healthCheck/healthCheck';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Widget',
    component: Widget
  },
  {
    path: '/healthz',
    exact: true,
    name: 'Health Check',
    component: healthCheck
  }
];

export default routes;
