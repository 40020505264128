import React, { PureComponent } from 'react';
import styles from './Charts.module.scss';
import inlineStyles from './InlineChartStyles';
import Toggle from '../../components/Toggle/Toggle';
import TemperatureChart from '../../components/TemperatureChart/TemperatureChart';
import RainChart from '../../components/RainChart/RainChart';
import SnowChart from '../../components/SnowChart/SnowChart';

class Charts extends PureComponent {
  render() {
    const {
      handleOnToggle,
      activeChart,
      handleOnChartClick,
      weekOfObj,
      stylesheet,
      colorMode,
      buildGAEvent,
      temperatureData,
      rainData,
      snowData,
      renderedCharts
    } = this.props;

    const chartProps = {
      handleOnChartClick,
      weekOfObj,
      inlineStyles: inlineStyles[stylesheet][colorMode],
      buildGAEvent
    };

    const renderActiveChart = () => {
      let activeChartComponent = (
        <TemperatureChart
          data={temperatureData}
          chartName="tempChart"
          chartProps={chartProps}
          weekOfObj={weekOfObj}
        />
      );

      if (activeChart === 'rainChart') {
        activeChartComponent = (
          <RainChart
            data={rainData}
            chartName="rainChart"
            chartProps={chartProps}
            weekOfObj={weekOfObj}
          />
        );
      }

      if (activeChart === 'snowChart') {
        activeChartComponent = (
          <SnowChart
            data={snowData}
            chartName="snowChart"
            chartProps={chartProps}
            weekOfObj={weekOfObj}
          />
        );
      }

      return activeChartComponent;
    };

    const toggleCharts = (event) => {
      if (!event.currentTarget || !event.currentTarget.getAttribute('toggleto'))
        return;

      const toggleTo = event.currentTarget.getAttribute('toggleto');

      buildGAEvent({
        eventType: 'selectItem',
        params: {
          itemListName: 'charts',
          items: [toggleTo]
        }
      });

      handleOnToggle(toggleTo);
    };

    return (
      <div className={styles[stylesheet]}>
        <div className={styles.container}>
          <Toggle
            handleOnToggle={toggleCharts}
            activeChart={activeChart}
            stylesheet={stylesheet}
            colorMode={colorMode}
            renderedCharts={renderedCharts}
          />
          <div className={styles.chart} id={activeChart}>
            {renderActiveChart()}
          </div>
        </div>
      </div>
    );
  }
}

export default Charts;
