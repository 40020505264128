import React, { useState } from 'react';
import styles from './ActivitySelect.module.scss';
import ScrollBar from '../ScrollBar/ScrollBar';
import activityDisplayNames from '../../formats/activityDisplayNames';
import { ReactComponent as ExpandCollapse } from '../../assets/icons/expand-collapse.svg';

const ActivitySelect = ({
  activity,
  activityScores,
  handleOnChange,
  stylesheet,
  colorMode,
  buildGAEvent
}) => {
  const [rendered, setRendered] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const stylesheetWrapper = stylesheet;

  const onFocus = () => {
    buildGAEvent({
      eventType: 'viewItemList',
      params: {
        itemListName: 'activities_list',
        action: 'expand'
      }
    });

    setRendered(true);
  };

  const onBlur = () => {
    buildGAEvent({
      eventType: 'viewItemList',
      params: {
        itemListName: 'activities_list',
        action: 'collapse'
      }
    });

    setRendered(false);
  };

  const renderDropdown = (event) => {
    event.preventDefault();
    document.getElementById('activityDropdown').focus();
  };

  const removeDropdown = (event) => {
    event.preventDefault();
    document.getElementById('activityDropdown').blur();
  };

  const getScrollingListElement = () => {
    return document.getElementById('activitiesSelectWrapper');
  };

  const updateScrollPercent = (percent) => {
    if (percent >= 0 && percent <= 1) {
      setScrollPercent(percent);
    }
  };

  const handleListScroll = (event) => {
    event.preventDefault();
    const element = getScrollingListElement();

    if (element && element.scrollTop !== scrollPercent) {
      // element.scrollHeight - element.offsetHeight = max that element will scroll
      const maxScrollHeight = element.scrollHeight - element.offsetHeight;
      const percentOfTotalScrolled = element.scrollTop / maxScrollHeight;

      updateScrollPercent(percentOfTotalScrolled);
    }
  };

  const handleScrollBarDrag = (percent) => {
    const element = getScrollingListElement();
    const maxScrollHeight = element.scrollHeight - element.offsetHeight;

    element.scrollTop = maxScrollHeight * percent;

    updateScrollPercent(percent);
  };

  const handleActivitySelect = (event) => {
    if (!event || !event.currentTarget) return;
    const value = event.currentTarget.getAttribute('value');

    buildGAEvent({
      eventType: 'selectItem',
      params: {
        itemListName: 'activities_list',
        items: [value]
      }
    });

    handleOnChange(event);
  };

  const textScoreMapper = {
    Great: 4,
    Good: 3,
    Okay: 2,
    Poor: 1
  };

  // Sorts first based on overall numeric score, then by text score. Text scores are adjusted in the proxy score based on lowest individual weather score.
  // i.e. If lowest individual weather score is "poor", then the highest text score it can have is "Good", even if overall score is above the "Great" threshold.
  // Without second sort, the ranked list would sometimes read "Great, Good, Great, Great" where it should really be "Great, Great, Great, Good".
  const rankScoresBestToWorst = () => {
    const keysByNumScore = Object.keys(activityScores).sort((a, b) => {
      return activityScores[b].score - activityScores[a].score;
    });

    const keysByTextScore = keysByNumScore.sort((a, b) => {
      const textScoreA = activityScores[a].overallScore;
      const textScoreB = activityScores[b].overallScore;

      return textScoreMapper[textScoreB] - textScoreMapper[textScoreA];
    });

    return keysByTextScore.map((k) => {
      return activityScores[k];
    });
  };

  const getDisplayName = (activityName) => {
    return activityDisplayNames[activityName] || activityName;
  };

  const renderActivitiesList = () => {
    const rankedScores = rankScoresBestToWorst();
    return rankedScores.map((a) => {
      const displayName = getDisplayName(a.activity_name);
      return (
        <div
          value={a.activity_name}
          key={a.activity_name}
          className={styles.item}
          id="activity"
          onMouseDown={handleActivitySelect}
        >
          {displayName}
        </div>
      );
    });
  };

  const renderSelectContainer = () => {
    return (
      <div className={styles.selectContainer}>
        <div
          className={styles.selectWrapper}
          id="activitiesSelectWrapper"
          onScroll={handleListScroll}
        >
          <div className={styles.select} data-testid="activitiesSelect">
            {renderActivitiesList()}
          </div>
        </div>
        <ScrollBar
          scrollPercent={scrollPercent}
          colorMode={colorMode}
          handleScrollBarDrag={handleScrollBarDrag}
        />
      </div>
    );
  };

  return (
    <div
      className={
        rendered
          ? `${styles[stylesheetWrapper]} ${styles[colorMode]} ${styles.container} ${styles.rendered}`
          : `${styles[stylesheetWrapper]} ${styles[colorMode]} ${styles.container} ${styles.notRendered}`
      }
    >
      <div className={styles.inputWrapper}>
        <input
          className={styles.activity}
          id="activityDropdown"
          type="button"
          value={getDisplayName(activity)}
          onFocus={onFocus}
          onBlur={onBlur}
          // Chrome requires calling removeDropdown to collapse input when expanded and clicked
          onMouseDown={rendered ? removeDropdown : renderDropdown}
        />
        <div className={styles.icon}>
          <ExpandCollapse
            className={rendered ? styles.collapse : styles.expand}
            onMouseDown={rendered ? null : renderDropdown}
          />
        </div>
      </div>
      {rendered ? renderSelectContainer() : null}
    </div>
  );
};

export default ActivitySelect;
