import React, { PureComponent } from 'react';
import Chart from '../Chart/Chart';

/* eslint-disable no-underscore-dangle */
class SnowChart extends PureComponent {
  state = {
    dataIndex: 0
  };

  setDataIndexState = (i) => {
    if (!this._isMounted) return;

    this.setState({
      dataIndex: i
    });
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { data, chartProps, chartName, weekOfObj } = this.props;

    const numTicks = 12;
    // Should be multiple of numTicks
    const chartMax = 108;

    const { snowBlue, labelColor } = chartProps.inlineStyles;

    const config = {
      mainDataSet: data,
      chart: {
        id: chartName
      },
      markers: {
        strokeColors: [snowBlue],
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: this.state.dataIndex,
            fillColor: snowBlue,
            strokeColor: snowBlue,
            size: 5
          }
        ]
      },
      tooltip: {
        y: {
          formatter: (value) => {
            let chance = value;

            if (chance < 1) chance = 'less than 1';
            if (chance > 99) chance = 'more than 99';

            return `There is a ${chance}% chance of having good snow conditions.`;
          },
          title: {
            formatter: () => {
              '';
            }
          }
        }
      },
      dataLabels: {
        offsetX: 0,
        offsetY: -8
      },
      colors: [snowBlue],
      legend: {
        formatter: () => {
          return 'Sensible Snow Score';
        }
      },
      yaxis: {
        tickAmount: numTicks,
        min: 0,
        max: chartMax,
        labels: {
          minWidth: 45,
          offsetX: 5,
          formatter: (value) => {
            const roundedVal = value.toFixed(2);
            if (roundedVal === (chartMax / numTicks).toFixed(2)) {
              return 'A Little';
            }

            if (roundedVal === (chartMax - chartMax / numTicks).toFixed(2)) {
              return 'A Lot';
            }
          },
          style: {
            colors: labelColor
          }
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      series: [
        {
          type: 'line',
          name: 'Sensible Snow Score',
          data
        }
      ]
    };

    return (
      <Chart
        weekOfObj={weekOfObj}
        chartProps={chartProps}
        chartConfig={config}
        setDataIndexState={this.setDataIndexState}
        unit="%"
        dataIndex={this.state.dataIndex}
      />
    );
  }
}

export default SnowChart;
