const locations = [
  'Venice Beach, Los Angeles, CA, USA',
  'Rockefeller Center, Rockefeller Plaza, New York, NY, USA',
  'Lincoln Memorial, Lincoln Memorial Circle Northwest, Washington, DC, USA',
  'Wrigley Field, West Addison Street, Chicago, IL, USA',
  'The Driskill Bar, Brazos Street, Austin, TX, USA',
  'Bayside Marketplace, Biscayne Boulevard, Miami, FL, USA',
  'Bourbon Street, New Orleans, LA, USA',
  'Caesars Palace, South Las Vegas Boulevard, Las Vegas, NV, USA',
  'Alaska Native Heritage Center, Heritage Center Drive, Anchorage, AK, USA',
  'Kaanapali Beach, Kaanapali, HI, USA',
  'Old Point Loma Lighthouse, Cabrillo Memorial Drive, San Diego, CA, USA',
  'Oregon Cabaret Theatre, Hargadine Street, Ashland, OR, USA',
  'High West Saloon, Park Avenue, Park City, UT, USA',
  'Rainbow Row, East Bay Street, Charleston, SC, USA',
  'Telluride Brewing Company, Society Drive, Telluride, CO, USA',
  'Half Dome Village, Yosemite Valley, CA, USA',
  'Rainbow Falls Trail, Gatlinburg, TN, USA',
  'Old Faithful, Yellowstone National Park, WY, USA',
  'South Kaibab Trailhead, Grand Canyon Village, AZ, USA',
  'Cadillac Mountain, Bar Harbor, ME, USA'
];

export default locations;
