const decimalToPercent = (dec) => {
  let percent = Math.round(dec * 100);

  if (percent > 99) {
    percent = 99;
  }

  if (percent < 1) {
    percent = 1;
  }
  return percent;
};

const decimalToPercentInverse = (dec) => {
  const percent = decimalToPercent(dec);
  return 100 - percent;
};

const cToF = (celsius) => {
  return Math.round((celsius * 9) / 5) + 32;
};

const msToMPH = (ms) => {
  const toMPH = 2.23694;
  return Math.round(ms * toMPH);
};

const mToIn = (m) => {
  const toInches = 39.3701;
  return Math.round(m * toInches);
};

const percentVarPlaceholder = '__PERCENT__';
const activityVarPlaceholder = '__ACTIVITY__';
const minVarPlaceholder = '__MIN__';
const maxVarPlaceholder = '__MAX__';

const tooltips = {
  shared: {
    percentVarPlaceholder,
    activityVarPlaceholder,
    minVarPlaceholder,
    maxVarPlaceholder
  },
  weatherElementData: {
    temperature_c_2m: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of comfortable temperatures for ${activityVarPlaceholder} (between ${minVarPlaceholder}°F and ${maxVarPlaceholder}°F)`,
      conversionCalc: cToF,
      getPercentFromDecimal: decimalToPercent
    },
    precipitation_m_surface: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of rain`,
      getPercentFromDecimal: decimalToPercentInverse
    },
    cloudfraction_percent_all: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of good sun for ${activityVarPlaceholder}`,
      getPercentFromDecimal: decimalToPercent
    },
    windspeed_mps_10m: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of good wind conditions for ${activityVarPlaceholder} (between ${minVarPlaceholder} and ${maxVarPlaceholder}mph)`,
      conversionCalc: msToMPH,
      getPercentFromDecimal: decimalToPercent
    },
    relativehumidity_percent_2m: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of comfortable humidity (between ${minVarPlaceholder}% and ${maxVarPlaceholder}% humidity)`,
      conversionCalc: decimalToPercent,
      getPercentFromDecimal: decimalToPercent
    },
    snowfall_probability_weekly: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance it will snow this week`,
      getPercentFromDecimal: decimalToPercent
    },
    rainfall_probability_weekly: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance it will rain this week`,
      getPercentFromDecimal: decimalToPercentInverse
    },
    snowdepth_m_surface: {
      textWithPlaceholders: `There is a ${percentVarPlaceholder}% chance of good snow cover`,
      conversionCalc: mToIn,
      getPercentFromDecimal: decimalToPercent
    }
  }
};

export default tooltips;
