import { ReactComponent as Thermometer } from '../../assets/icons/thermometer.svg';
import { ReactComponent as Umbrella } from '../../assets/icons/umbrella.svg';
import { ReactComponent as Wind } from '../../assets/icons/windy.svg';
import { ReactComponent as Sun } from '../../assets/icons/sun.svg';
import { ReactComponent as Humidity } from '../../assets/icons/humidity.svg';
import { ReactComponent as Snow } from '../../assets/icons/snow.svg';
import { ReactComponent as SnowDepth } from '../../assets/icons/snow-depth.svg';

export default {
  Thermometer,
  Umbrella,
  Wind,
  Sun,
  Humidity,
  Snow,
  SnowDepth
};
