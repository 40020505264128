const defaultConfig = {
  options: {
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    markers: {
      size: 0.1,
      strokeWidth: [0, 0]
    },
    tooltip: {
      enabled: true,
      shared: true,
      marker: {
        show: false
      }
    },
    annotations: {
      xaxis: {
        label: {
          borderColor: 'none',
          position: 'top',
          orientation: 'horizontal',
          text: ``
        }
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec'
      ],
      type: 'datetime'
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'right',
      fontSize: '12px',
      offsetY: 15,
      onItemClick: {
        toggleDataSeries: false
      }
    }
  }
};

export default defaultConfig;
