const axios = require('axios');

const getConfig = async () => {
  const url = 'api/config';

  try {
    return axios.get(url);
  } catch (err) {
    return err;
  }
};

const getCoordinates = async ({ location }) => {
  const url = 'api/coordinates';

  try {
    return axios.get(url, {
      params: {
        location
      }
    });
  } catch (err) {
    return err;
  }
};

const getClimatology = async ({ lat, lon, formattedDate }) => {
  const url = 'api/climatology';

  try {
    return axios.get(url, {
      params: {
        lat,
        lon,
        date: formattedDate
      },
      timeout: 180000
    });
  } catch (err) {
    return err;
  }
};

export default {
  getConfig,
  getCoordinates,
  getClimatology
};
