import React, { PureComponent } from 'react';
import styles from './Widget.module.scss';
import Inputs from '../../containers/Inputs/Inputs';
import Charts from '../../containers/Charts/Charts';
import Activity from '../../containers/Activity/Activity';
import ErrorAnimation from '../../components/ErrorAnimation/ErrorAnimation';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import GoogleErrorAnimation from '../../components/GoogleErrorAnimation/GoogleErrorAnimation';
import ActivityAPI from '../../api/activity';
import LocationPicker from '../../locationPicker';
import GA from '../../googleAnalytics/googleAnalytics';
import DateFormats from '../../formats/date';

const uniqid = require('uniqid');

class Widget extends PureComponent {
  // renderedDateObj stored as moment object
  // dateInput stored as 'MM-DD-YYYY'
  // weekOf stored as moment object

  state = {
    renderedLocation: '',
    inputLocation: '',
    renderedCoords: {},
    renderedDateObj: DateFormats.defaultDate(),
    dateInput: DateFormats.formattedMomentDate(DateFormats.defaultDate()),
    weekOfObj: DateFormats.getWeekOfObject(DateFormats.defaultDate()),
    activity: '',
    activityScores: {},
    ranges: {},
    rainData: {},
    temperatureData: {},
    snowData: {},
    renderedCharts: ['tempChart', 'rainChart', 'snowChart'],
    activeChart: 'tempChart',
    inputError: '',
    googleError: '',
    weatherError: '',
    loading: true,
    loadingAnimation: true,
    partnerId: '',
    insuranceClickThrough: false,
    allowDarkMode: false,
    gaClientId: '',
    stylesheet: 'classic',
    colorMode: 'light'
  };

  resetErrors = {
    inputError: '',
    googleError: '',
    weatherError: ''
  };

  setInputError = (errorMsg) => {
    return this.setState({
      loading: false,
      loadingAnimation: false,
      inputError: errorMsg,
      googleError: '',
      weatherError: ''
    });
  };

  setGoogleError = (errorMsg) => {
    return this.setState({
      loading: false,
      loadingAnimation: false,
      inputError: '',
      googleError: errorMsg,
      weatherError: ''
    });
  };

  setWeatherError = (errorMsg) => {
    return this.setState({
      loading: false,
      loadingAnimation: false,
      inputError: '',
      googleError: '',
      weatherError: errorMsg
    });
  };

  weatherErrorMsg = 'ERROR FETCHING DATA PLEASE TRY AGAIN';

  loadingAnimationDelay = () => {
    setTimeout(() => {
      if (this.state.loading) {
        this.setState({
          loadingAnimation: true
        });
      }
    }, 1000);
  };

  getQueryParams = (queryString) => {
    const queryStrings = queryString.split('&');
    const queryParams = {};
    const queryStringsCopy = [...queryStrings];

    // Removes ? from query string
    queryStringsCopy[0] = queryStrings[0].substring(1, queryStrings[0].length);

    queryStringsCopy.forEach((q) => {
      const keyVal = q.split('=');
      const key = keyVal[0];
      const val = keyVal[1];

      if (key && val && key.length > 0 && val.length > 0) {
        const formatVal = val.split('%20').join(' ');
        queryParams[key] = formatVal;
      }
    });

    return queryParams;
  };

  getRandomLocation = () => {
    const randNum = Math.floor(Math.random() * LocationPicker.length);
    return LocationPicker[randNum];
  };

  getPartnerParamValues = (queryParams) => {
    const values = {};

    const paramIsTrue = (param) => {
      return !!param && (param === '1' || param === 'true');
    };

    if (queryParams.partner_id) values.partnerId = queryParams.partner_id;
    if (queryParams.stylesheet) values.stylesheet = queryParams.stylesheet;

    if (paramIsTrue(queryParams.click_through))
      values.insuranceClickThrough = true;
    if (paramIsTrue(queryParams.allow_darkmode)) values.allowDarkMode = true;

    if (
      values.allowDarkMode &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      document.getElementById(
        'sensible-answers-widget-body'
      ).style.backgroundColor = '#111111';
      values.colorMode = 'dark';
    }

    return values;
  };

  getInitialInputValues = async (queryParams) => {
    const values = {};
    values.inputLocation = this.getRandomLocation();

    if (queryParams.location) {
      const googleRes = await ActivityAPI.getCoordinates({
        location: queryParams.location
      });

      if (googleRes.data.coords && googleRes.data.location) {
        values.inputLocation = googleRes.data.location;
        values.renderedCoords = googleRes.data.coords;
      }
    }

    if (queryParams.date && DateFormats.isValid(queryParams.date)) {
      values.renderedDateObj = DateFormats.momentObject(queryParams.date);
      values.weekOfObj = DateFormats.getWeekOfObject(values.renderedDateObj);
      values.dateInput = DateFormats.formattedMomentDate(queryParams.date);
    }

    if (queryParams.activity) values.activity = queryParams.activity;

    return values;
  };

  getAndSetInitialState = async () => {
    let [partnerParamValues, initialInputValues] = [{}, {}];

    if (window.location.search.length > 0) {
      const queryParams = this.getQueryParams(window.location.search);

      partnerParamValues = this.getPartnerParamValues(queryParams);
      initialInputValues = await this.getInitialInputValues(queryParams);
    }

    return this.setState({
      ...partnerParamValues,
      ...initialInputValues,
      inputLocation:
        initialInputValues.inputLocation || this.getRandomLocation()
    });
  };

  getInitialCoords = async () => {
    const { renderedCoords, inputLocation } = this.state;
    if (renderedCoords.lat && renderedCoords.lon) return renderedCoords;

    try {
      const googleRes = await ActivityAPI.getCoordinates({
        location: inputLocation
      });
      return googleRes.data.coords;
    } catch (err) {
      return err;
    }
  };

  getHighestScoreActivity = (activities, scores) => {
    return activities.reduce((a, b) => {
      return scores[a].score >= scores[b].score ? a : b;
    });
  };

  getActivityForState = ({ weekOfObj, currentActivity, scores }) => {
    const formattedWeekOf = DateFormats.formattedMomentDate(weekOfObj);
    const scoresForWeek = scores[formattedWeekOf];
    const activities = Object.keys(scoresForWeek);

    if (activities.includes(currentActivity)) {
      return currentActivity;
    }

    return this.getHighestScoreActivity(activities, scoresForWeek);
  };

  getRenderedCharts = (temperatureData, rainData, snowData) => {
    const renderedCharts = [];

    const isNotEmpty = (d) => {
      return d.length > 1;
    };

    if (isNotEmpty(temperatureData.highs)) {
      renderedCharts.push('tempChart');
    }

    if (isNotEmpty(rainData)) {
      renderedCharts.push('rainChart');
    }

    if (isNotEmpty(snowData)) {
      renderedCharts.push('snowChart');
    }

    return renderedCharts;
  };

  fetchData = async () => {
    await this.getAndSetInitialState();

    try {
      const coords = await this.getInitialCoords();

      if (!coords) {
        return this.setGoogleError(
          'Unable to get location coordinates at this time. Please try again later.'
        );
      }

      const { lat, lon } = coords;

      const res = await ActivityAPI.getClimatology({
        lat,
        lon,
        formattedDate: this.state.dateInput
      });

      if (res.status !== 200) {
        throw new Error('Error fetching climatology data');
      }

      const { temperature, rain, snow, scores, ranges } = res.data;
      const renderedCharts = this.getRenderedCharts(temperature, rain, snow);

      const activity = this.getActivityForState({
        weekOfObj: this.state.weekOfObj,
        currentActivity: this.state.activity,
        scores
      });

      return this.setState({
        renderedLocation: this.state.inputLocation,
        renderedCoords: coords,
        activity,
        activityScores: scores,
        ranges,
        rainData: rain,
        temperatureData: temperature,
        snowData: snow,
        renderedCharts,
        loading: false,
        loadingAnimation: false,
        ...this.resetErrors
      });
    } catch (err) {
      return this.setWeatherError(this.weatherErrorMsg);
    }
  };

  createAndSetGAClientId = () => {
    const uniqueId = uniqid.time();
    const randomPrefix = Math.floor(Math.random() * Math.floor(100000));
    const randomSuffix = Math.floor(Math.random() * Math.floor(100000));
    return `${randomPrefix}-${uniqueId}-${randomSuffix}`;
  };

  buildGASessionStartEvent = () => {
    const { search } = window.location;
    const queryParams = this.getQueryParams(search);
    const { renderedDate, renderedLocation } = this.getRenderedDateAndLocation();

    const params = {
      location: queryParams.location || null,
      date: queryParams.date || null,
      activity: queryParams.activity || null,
      partner_id: queryParams.partner_id || null,
      rendered_date: renderedDate,
      rendered_location: renderedLocation
    };

    GA.sessionStart({ params });
  }

  gaInit = () => {
    const clientId = this.createAndSetGAClientId();

    try {
      GA.createTracker({ clientId });
    } catch (err) {
      return err;
    }
  };

  mountWidget = async () => {
    await this.gaInit();
    await this.fetchData();

    this.buildGASessionStartEvent();
  };

  componentDidMount() {
    this.mountWidget();
  }

  getRenderedDateAndLocation = () => {
    const { renderedDateObj } = this.state;
    const formattedRenderedDate = DateFormats.formattedMomentDate(
      renderedDateObj
    );

    // These vals are whatever is rendered when event took place
    const renderedLocation = this.state.renderedLocation || null;
    const renderedDate = formattedRenderedDate || null;

    return { renderedDate, renderedLocation }
  }

  buildGAEvent = ({ eventType, params }) => {
    const { renderedDate, renderedLocation } = this.getRenderedDateAndLocation();
    const args = { params, renderedLocation, renderedDate };

    switch (eventType) {
      case 'search':
        return GA.search({ ...args });
      case 'click':
        return GA.click({ ...args });
      case 'viewItemList':
        return GA.viewItemList({ ...args });
      case 'selectItem':
        return GA.selectItem({ ...args });
      case 'userEngagement':
        return GA.userEngagement({ ...args });
      case 'generateLead':
        return GA.generateLead({ ...args });
      default:
        return 'GA event type not found';
    }
  };

  // CHANGE STATE HANDLERS
  handleOnChange = (event) => {
    if (!event || !event.currentTarget) return;

    const { id } = event.currentTarget;
    let { value } = event.currentTarget;

    if (id === 'activity') {
      value = event.currentTarget.getAttribute('value');
    }

    const stateObj = {};
    stateObj[id] = value;

    this.setState({
      ...stateObj
    });
  };

  handleGoogleNavigation = (input) => {
    return this.setState({
      inputLocation: input
    });
  };

  handleOnToggle = (toggleTo) => {
    return this.setState({
      activeChart: toggleTo
    });
  };

  handleOnDateChange = (date) => {
    let updatedInput = date;
    if (date === 'Invalid date') {
      updatedInput = '';
    }

    return this.setState({
      dateInput: updatedInput
    });
  };

  // FORM & DATE SUBMISSION HANDLERS
  handleOnSubmit = async (event) => {
    event.preventDefault();

    return this.sendRequest();
  };

  handleOnDateSelect = async (date) => {
    await this.handleOnDateChange(date);

    this.sendRequest();
  };

  handleOnChartClick = async (date) => {
    const { activityScores } = this.state;
    const momentObj = DateFormats.momentObject(date);
    const formattedMomentDate = DateFormats.formattedMomentDate(momentObj);

    if (activityScores[date]) {
      return this.setState({
        renderedDateObj: momentObj,
        weekOfObj: momentObj,
        dateInput: formattedMomentDate,
        ...this.resetErrors
      });
    }

    return this.handleOnDateSelect(date);
  };

  validateLocationInput = (input) => {
    return input.length > 0;
  };

  validateDateInput = (input) => {
    return (
      !!input &&
      input.length > 0 &&
      DateFormats.isValid(input) &&
      DateFormats.year(input) > 1900
    );
  };

  sendRequest = async () => {
    const {
      inputLocation,
      renderedLocation,
      dateInput,
      activity,
      activityScores,
      loading
    } = this.state;

    if (loading) return;

    if (!this.validateLocationInput(inputLocation)) {
      return this.setInputError('Please enter a location.');
    }

    if (!this.validateDateInput(dateInput)) {
      return this.setInputError('Please enter a valid date.');
    }

    const momentDate = DateFormats.momentObject(dateInput);
    const jsDate = DateFormats.dateToJS(dateInput);
    const date = DateFormats.isValid(momentDate)
      ? momentDate
      : DateFormats.momentObject(jsDate);
    const formattedDate = DateFormats.formattedMomentDate(date);
    const weekOfObj = DateFormats.getWeekOfObject(date);

    const stateContainsRequestedData = () => {
      const weekOf = DateFormats.formattedMomentDate(weekOfObj);
      return inputLocation === renderedLocation && activityScores[weekOf];
    };

    const gaEvent = {
      eventType: 'search',
      params: {
        searchTerm: inputLocation,
        searchDate: dateInput,
        searchType: 'form_submit'
      }
    };

    if (stateContainsRequestedData()) {
      gaEvent.params.requestSent = 0;
      this.buildGAEvent({ ...gaEvent });

      return this.setState({
        renderedDateObj: date,
        dateInput: formattedDate,
        weekOfObj,
        ...this.resetErrors
      });
    }

    gaEvent.params.requestSent = 1;
    this.buildGAEvent({ ...gaEvent });

    this.setState({
      dateInput: formattedDate,
      loading: true,
      inputError: ''
    });

    this.loadingAnimationDelay();

    try {
      const googleRes = await ActivityAPI.getCoordinates({
        location: inputLocation
      });

      if (!googleRes.data || !googleRes.data.coords) {
        return this.setGoogleError(
          'Unable to fetch coordinates for this location. Please try again.'
        );
      }

      const { coords } = googleRes.data;
      const { lat, lon } = coords;

      const res = await ActivityAPI.getClimatology({ lat, lon, formattedDate });

      if (res.status !== 200) {
        throw new Error('Error fetching climatology data');
      }

      const { scores, rain, temperature, snow } = res.data;

      const renderedCharts = this.getRenderedCharts(temperature, rain, snow);

      const updatedActivity = this.getActivityForState({
        weekOfObj,
        currentActivity: activity,
        scores
      });

      const activeChart = renderedCharts.includes(this.state.activeChart)
        ? this.state.activeChart
        : 'tempChart';

      return this.setState({
        renderedLocation: inputLocation,
        inputLocation,
        renderedCoords: coords,
        renderedDateObj: date,
        weekOfObj,
        activity: updatedActivity,
        activityScores: scores,
        rainData: rain,
        temperatureData: temperature,
        snowData: snow,
        activeChart,
        renderedCharts,
        ...this.resetErrors,
        loading: false,
        loadingAnimation: false
      });
    } catch (err) {
      if (err.response && err.response.data.googleError) {
        return this.setGoogleError(
          'Unable to fetch coordinates for this location. Please try again.'
        );
      }

      return this.setWeatherError(this.weatherErrorMsg);
    }
  };

  render() {
    const {
      inputLocation,
      weekOfObj,
      dateInput,
      activity,
      activityScores,
      ranges,
      rainData,
      temperatureData,
      snowData,
      activeChart,
      renderedCharts,
      loadingAnimation,
      inputError,
      googleError,
      weatherError,
      insuranceClickThrough,
      stylesheet,
      colorMode,
      loading
    } = this.state;

    const renderCharts = () => {
      if (loadingAnimation) {
        return <LoadingAnimation />;
      }

      if (weatherError.length > 0) {
        return <ErrorAnimation message={weatherError} colorMode={colorMode} />;
      }

      if (googleError.length > 0) {
        return (
          <GoogleErrorAnimation message={googleError} stylesheet={stylesheet} />
        );
      }

      return (
        <Charts
          handleOnToggle={this.handleOnToggle}
          handleOnChartClick={this.handleOnChartClick}
          rainData={rainData}
          temperatureData={temperatureData}
          renderedCharts={renderedCharts}
          snowData={snowData}
          activeChart={activeChart}
          weekOfObj={weekOfObj}
          stylesheet={stylesheet}
          colorMode={colorMode}
          buildGAEvent={this.buildGAEvent}
        />
      );
    };

    return (
      <div
        className={`${styles.container} ${styles[colorMode]}`}
        data-testid="widgetContainer"
      >
        <div className={styles.wrapper}>
          <Inputs
            handleOnChange={this.handleOnChange}
            handleOnSubmit={this.handleOnSubmit}
            handleOnDateSelect={this.handleOnDateSelect}
            handleOnDateChange={this.handleOnDateChange}
            handleGoogleNavigation={this.handleGoogleNavigation}
            location={inputLocation}
            dateInput={dateInput}
            error={inputError}
            stylesheet={stylesheet}
            colorMode={colorMode}
            loading={loading}
            buildGAEvent={this.buildGAEvent}
          />
          {renderCharts()}
        </div>
        <Activity
          handleOnChange={this.handleOnChange}
          activity={activity}
          activityScores={activityScores}
          weekOfObj={weekOfObj}
          ranges={ranges}
          insuranceClickThrough={insuranceClickThrough}
          stylesheet={stylesheet}
          colorMode={colorMode}
          loading={loading}
          buildGAEvent={this.buildGAEvent}
        />
      </div>
    );
  }
}

export default Widget;
