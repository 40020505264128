import React, { PureComponent } from 'react';
import styles from './Activity.module.scss';
import ActivityHeader from '../../components/ActivityHeader/ActivityHeader';
import ActivityFooter from '../../components/ActivityFooter/ActivityFooter';
import ScoreScale from '../../components/ScoreScale/ScoreScale';
import tooltips from '../../formats/tooltips';
import DateFormats from '../../formats/date';
import weatherIcons from './WeatherIcons';

/* eslint array-callback-return: ["error", { allowImplicit: true }] */
class Activity extends PureComponent {
  render() {
    const {
      activity,
      weekOfObj,
      activityScores,
      handleOnChange,
      insuranceClickThrough,
      ranges,
      stylesheet,
      colorMode,
      loading,
      buildGAEvent
    } = this.props;

    const formattedWeekOf = DateFormats.formattedMomentDate(weekOfObj);

    const weekScores = activityScores[formattedWeekOf];
    const activityScore = weekScores ? weekScores[activity] : {};
    const stylesheetWrapper = stylesheet;

    const {
      Thermometer,
      Umbrella,
      Wind,
      Sun,
      Humidity,
      Snow,
      SnowDepth
    } = weatherIcons;

    const displayData = {
      temperature_c_2m: {
        icon: <Thermometer className={styles.icon} />,
        name: 'Temp'
      },
      precipitation_m_surface: {
        icon: <Umbrella className={styles.icon} />,
        name: 'Rain'
      },
      rainfall_probability_weekly: {
        icon: <Umbrella className={styles.icon} />,
        name: 'Rain'
      },
      relativehumidity_percent_2m: {
        icon: <Humidity className={styles.icon} />,
        name: 'Humidity'
      },
      cloudfraction_percent_all: {
        icon: <Sun className={styles.icon} />,
        name: 'Sun'
      },
      windspeed_mps_10m: {
        icon: <Wind className={styles.icon} />,
        name: 'Wind'
      },
      snowfall_probability_weekly: {
        icon: <Snow className={styles.icon} />,
        name: 'Snow'
      },
      snowdepth_m_surface: {
        icon: <SnowDepth className={styles.icon} />,
        name: 'Snow Cover'
      }
    };

    const renderScores = () => {
      const scores = { ...activityScore };
      delete scores.score;

      let rangeObj = {};
      ranges.forEach(r => {
        rangeObj[r.name] = r.weather_range
      })
      const keys = Object.keys(scores.weather_scores);
      const activityRanges = rangeObj[activity];

      if (!activityRanges)
        return 'Error Displaying Activity Data. Please try again.';

      const sortedKeys = keys.sort((a, b) => {
        return activityRanges[b].weight - activityRanges[a].weight;
      });

      return sortedKeys.map((key) => {
        if (!displayData[key]) return;

        const { name, icon } = displayData[key];

        let tooltipText = name;
        const score = scores.weather_scores[key];

        if (tooltips.weatherElementData[key]) {
          const {
            percentVarPlaceholder,
            activityVarPlaceholder,
            minVarPlaceholder,
            maxVarPlaceholder
          } = tooltips.shared;

          const {
            textWithPlaceholders,
            conversionCalc,
            getPercentFromDecimal
          } = tooltips.weatherElementData[key];

          let { min, max } = activityRanges[key];

          if (conversionCalc) {
            min = conversionCalc(min);
            max = conversionCalc(max);
          }

          tooltipText = textWithPlaceholders;
          tooltipText = tooltipText.replace(
            percentVarPlaceholder,
            getPercentFromDecimal(score)
          );
          tooltipText = tooltipText.replace(activityVarPlaceholder, activity);
          tooltipText = tooltipText.replace(minVarPlaceholder, min);
          tooltipText = tooltipText.replace(maxVarPlaceholder, max);
        }

        return (
          <ScoreScale
            name={name}
            key={key}
            tooltipText={tooltipText}
            score={score}
            icon={icon}
            stylesheet={stylesheet}
            colorMode={colorMode}
            buildGAEvent={buildGAEvent}
            empty={false}
          />
        );
      });
    };

    const renderEmpty = () => {
      const keys = Object.keys(displayData);
      return keys.map((key) => {
        if (!displayData[key]) return;

        // TODO: Remove once only one rain variable is used
        if (key === 'rainfall_probability_weekly') return;

        const { name, icon } = displayData[key];
        return (
          <ScoreScale
            name={name}
            key={key}
            icon={icon}
            stylesheet={stylesheet}
            colorMode={colorMode}
            empty
          />
        );
      });
    };

    let headerProps = { stylesheet, colorMode };
    let renderScoresOrEmpty = renderEmpty();

    if (Object.entries(activityScores).length > 0 && !!ranges && !loading) {
      headerProps = {
        activity,
        activityScores: weekScores,
        weekOfObj,
        handleOnChange,
        stylesheet,
        colorMode,
        buildGAEvent
      };

      renderScoresOrEmpty = renderScores(activityScore, ranges);
    }

    return (
      <div className={`${styles[stylesheetWrapper]} ${styles[colorMode]}`}>
        <hr />
        <div className={styles.container} data-testid="activityContainer">
          <ActivityHeader {...headerProps} />
          <div className={styles.wrapper} data-testid="scoresWrapper">
            {renderScoresOrEmpty}
          </div>
          <ActivityFooter
            insuranceClickThrough={insuranceClickThrough}
            stylesheet={stylesheet}
            colorMode={colorMode}
            buildGAEvent={buildGAEvent}
          />
        </div>
      </div>
    );
  }
}

export default Activity;
