import React from 'react';
import Lottie from 'react-lottie';
import styles from './LoadingAnimation.module.scss';
import Satellite from '../../assets/animations/satellite.json';
import Fetching from '../../assets/animations/Noise-SquadaOne-Checking.png';

const LoadingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Satellite,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={styles.container}>
      <img
        src={Fetching}
        alt="Checking our satellites..."
        className={styles.fetching}
      />
      <Lottie options={defaultOptions} className={styles.animation} />
    </div>
  );
};

export default LoadingAnimation;
