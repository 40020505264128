import React, { PureComponent } from 'react';
import ApexCharts from 'apexcharts';
import ApexChartComponent from 'react-apexcharts';
import DateFormats from '../../formats/date';
import defaultConfig from './defaultConfig';

class Chart extends PureComponent {
  constructor(props) {
    super(props);

    const {
      chartConfig,
      chartProps,
      setDataIndexState,
      dataIndex,
      weekOfObj
    } = props;

    const enableDataLabels = chartConfig.chart.id === 'tempChart';

    this.state = {
      dataIndex,
      bodyWidth: '100%',
      chartHeight: '100%',
      options: {
        chart: {
          ...defaultConfig.options.chart,
          id: chartConfig.chart.id,
          offsetY: chartConfig.chart.offsetY,
          offsetX: chartConfig.chart.offsetX,
          events: {
            markerClick: (event, chartContext, { dataPointIndex }) => {
              const dateClicked = chartConfig.mainDataSet[dataPointIndex].x;

              chartProps.buildGAEvent({
                eventType: 'click',
                params: {
                  chart: chartConfig.chart.id,
                  selectedDate: dateClicked
                }
              });

              setDataIndexState(dataPointIndex);
              chartProps.handleOnChartClick(dateClicked);
            }
          }
        },
        markers: {
          ...defaultConfig.options.markers,
          strokeColors: chartConfig.markers.strokeColors,
          discrete: chartConfig.markers.discrete
        },
        tooltip: {
          ...defaultConfig.options.tooltip,
          theme: chartProps.inlineStyles.theme,
          x: {
            show: true,
            format: 'MMMM dd',
            formatter: (date) => {
              const jsDate = DateFormats.jsDate(date);
              const formattedDate = DateFormats.momentObject(jsDate).format(
                'MMM DD'
              );
              return `Week of ${formattedDate}`;
            }
          },
          y: chartConfig.tooltip.y || null
        },
        annotations: {
          xaxis: [
            {
              ...defaultConfig.options.annotations.xaxis,
              x: DateFormats.getAnnotatedTime(
                DateFormats.formattedMomentDate(weekOfObj)
              ),
              borderColor: chartProps.inlineStyles.annotations.borderColor
            }
          ]
        },
        grid: {
          show: chartProps.inlineStyles.showGrid,
          yaxis: {
            lines: {
              show: chartConfig.grid.yaxis.lines.show
            }
          }
        },
        xaxis: {
          ...defaultConfig.options.xaxis,
          labels: {
            datetimeFormatter: {
              month: 'MMM'
            },
            style: {
              colors: chartProps.inlineStyles.labelColor
            }
          }
        },
        axisBorder: chartProps.inlineStyles.axisBorder,
        axisTicks: chartProps.inlineStyles.axisTicks,
        stroke: {
          curve: 'smooth',
          dashArray: chartProps.inlineStyles.dashArray,
          width: chartProps.inlineStyles.strokeWidth,
          lineCap: 'square'
        },
        dataLabels: {
          enabled: enableDataLabels,
          formatter: (value, { dataPointIndex }) => {
            if (dataIndex === dataPointIndex) {
              return `${value}${this.props.unit}`;
            }
            return '';
          },
          textAnchor: 'middle',
          offsetX: chartConfig.dataLabels.offsetX,
          offsetY: chartConfig.dataLabels.offsetY,
          style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: chartProps.inlineStyles.dataLabels.backgroundColors
          },
          background: {
            enabled: true,
            foreColor: chartProps.inlineStyles.dataLabels.foreColor,
            padding: 0,
            borderColor: chartProps.inlineStyles.dataLabels.borderColor
          }
        },
        colors: chartConfig.colors,
        legend: {
          ...defaultConfig.options.legend,
          formatter: chartConfig.legend.formatter,
          labels: {
            colors: chartProps.inlineStyles.labelColor
          }
        },
        yaxis: chartConfig.yaxis
      },
      series: chartConfig.series
    };
  }

  updateAnnotationPoint = (annotations, formattedWeekOf) => {
    const updatedAnnotation = annotations.xaxis[0];
    updatedAnnotation.x = DateFormats.getAnnotatedTime(formattedWeekOf);
  };

  updateMarkerIndex = (markers, index) => {
    markers.discrete.forEach((m) => {
      m.dataPointIndex = index;
    });
  };

  getUpdatedFormatter = (index, unit) => {
    return (value, { dataPointIndex }) => {
      if (index === dataPointIndex) {
        return `${value}${unit}`;
      }

      return '';
    };
  };

  updateChart = () => {
    const { markers, annotations, dataLabels } = this.state.options;
    const { weekOfObj, chartConfig, unit } = this.props;

    const formattedWeekOf = DateFormats.formattedMomentDate(weekOfObj);
    const updatedDataIndex = this.findIndex(
      this.state.series[0].data,
      formattedWeekOf
    );

    if (updatedDataIndex === markers.discrete[0].dataPointIndex) {
      return null;
    }

    this.updateAnnotationPoint(annotations, formattedWeekOf);
    this.updateMarkerIndex(markers, updatedDataIndex);

    const updatedFormatter = this.getUpdatedFormatter(updatedDataIndex, unit);

    return ApexCharts.exec(
      chartConfig.chart.id,
      'updateOptions',
      { dataLabels: { ...dataLabels, formatter: updatedFormatter } },
      false,
      true
    );
  };

  getChartHeightFromBodyWidth = (bodyWidth) => {
    if (bodyWidth >= 900) return 268;
    if (bodyWidth < 900) return 190;
  };

  getAndSetChartHeight = () => {
    const chartEl = document.getElementById(this.props.chartConfig.chart.id);
    const bodyEl = document.getElementById('sensible-answers-widget-body');

    if (!chartEl || !chartEl.offsetHeight || !bodyEl || !bodyEl.offsetWidth)
      return;

    const chartHeight = this.getChartHeightFromBodyWidth(bodyEl.offsetWidth);

    return this.setState({
      chartHeight,
      bodyWidth: bodyEl.offsetWidth
    });
  };

  addOnresizeListener = () => {
    const checkForResize = () => {
      const bodyEl = document.getElementById('sensible-answers-widget-body');

      if (bodyEl.offsetWidth === this.state.bodyWidth) return;
      this.getAndSetChartHeight();
    };

    document.getElementById(
      'sensible-answers-widget-body'
    ).onresize = checkForResize;
  };

  findIndex = (data, weekOf) => {
    if (!data || !weekOf) return;

    return data.findIndex((d) => {
      const mmddFormatter = (date) => {
        return DateFormats.momentObject(date).utc().format('MM DD');
      };

      return mmddFormatter(d.x) === mmddFormatter(weekOf);
    });
  };

  getIndexFromProps = () => {
    const { chartConfig, weekOfObj } = this.props;
    const { mainDataSet } = chartConfig;

    const weekOfDate = DateFormats.formattedMomentDate(weekOfObj);
    if (!mainDataSet || !weekOfDate) return;

    return this.findIndex(mainDataSet, weekOfDate);
  };

  onMount = async () => {
    this.addOnresizeListener();
    await this.getAndSetChartHeight();
    this.props.setDataIndexState(this.getIndexFromProps());
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate(prevProps) {
    const weekOfHasChanged = this.props.weekOfObj !== prevProps.weekOfObj;
    const dataIndexHasChanged = this.props.dataIndex !== this.state.dataIndex;

    if (weekOfHasChanged || dataIndexHasChanged) {
      return this.updateChart();
    }
  }

  render() {
    return (
      <ApexChartComponent
        options={this.state.options}
        // series: data to display
        series={this.state.series}
        type="line"
        height={this.state.chartHeight}
        width="100%"
      />
    );
  }
}

export default Chart;
