import React from 'react';
import Lottie from 'react-lottie';
import styles from './GoogleErrorAnimation.module.scss';
import Satellite from '../../assets/animations/hotAirBalloon.json';

const LoadingAnimation = ({ message, stylesheet }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Satellite
  };

  const getMessageDivs = () => {
    if (!message) {
      return <div className={styles.error}>Error fetching location.</div>;
    }

    const [firstLine, secondLine] = message.split('. ');

    return (
      <>
        <div className={styles.error}>{firstLine}.</div>
        <div className={styles.error}>{secondLine || ''}</div>
      </>
    );
  };

  return (
    <div
      className={`${styles.container} ${styles[stylesheet]}`}
      data-testid="googleErrorContainer"
    >
      {getMessageDivs()}
      <Lottie
        options={defaultOptions}
        className={styles.animation}
        width="auto"
        height="auto"
      />
      <div className={styles.attribution}>
        Leonardo Amaral de Oliveira Richard on{' '}
        <a
          href="https://lottiefiles.com/14772-erro-404"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lottie Files
        </a>
      </div>
    </div>
  );
};

export default LoadingAnimation;
